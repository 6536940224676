import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Joyride from 'react-joyride';
import styled from 'styled-components';
import _ from 'lodash';

import { Box, Text } from 'grommet';

import { AppButton, AppAnchor } from '@Components/Control';
import { endTourRequest } from '@Actions';


const StyledText = styled(Text)`
  color: var(--primary-text);
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: 'Figtree', sans-serif;
  white-space: pre-line;
  line-height: 1.25;
`;

const renderTitle = (title) => (
  <Box background="white" pad="0.5rem">
    <StyledText size="1.25rem" weight={600} textAlign="start">
      {title}
    </StyledText>
  </Box>
);

const renderContent = (message) => (
  <Box background="white">
    <StyledText size="1rem" weight={400} textAlign="start">
      {message}
    </StyledText>
  </Box>
);

const GuidedTour = ({
  showTour,
  endTour,
  companyFeatures = null,
}) => {
  const handleEndTour = () => endTour();

  // Company Feature flags determine which steps to show in the tour
  const searchData = companyFeatures && _.find(companyFeatures, { key: 'search' });
  const searchEnabled = searchData?.enabled;
  const reviewData = companyFeatures && _.find(companyFeatures, { key: 'review' });
  const reviewEnabled = reviewData?.enabled;
  const overviewData = companyFeatures && _.find(companyFeatures, { key: 'overview_embed' });
  const overviewEnabled = overviewData?.enabled && overviewData?.config?.embedId !== null;
  const settingsData = companyFeatures && _.find(companyFeatures, { key: 'setting' });
  const settingsEnabled = settingsData?.enabled;
  const authorListsEnabled = settingsData?.config?.authorListsAccess && settingsEnabled;
  const domainListsEnabled = settingsData?.config?.dmcaDomainListsAccess && settingsEnabled;
  const reportsConfig = companyFeatures && _.find(companyFeatures, { key: 'reports_config' });
  const reportsKeys = reportsConfig?.config && reportsConfig?.enabled
    && Object.keys(reportsConfig?.config);
  const filteredReportsKeys = reportsKeys
    && reportsKeys.filter((key) => !_.isEmpty(reportsConfig.config[key]));
  const reportsEnabled = (reportsConfig?.enabled && filteredReportsKeys?.length > 0);

  const steps = [
    {
      target: '.tour-app-container',
      title: renderTitle('Welcome to the Redflag platform!'),
      content: renderContent('Take a quick tour of your dashboard to learn about the key features and start finding infringing content. Click next to continue.'),
      placement: 'center',
    },
    ...(searchEnabled
      ? [
        {
          target: '.tour-new-search',
          title: renderTitle('Create Searches'),
          content: renderContent('Create searches for your titles so our platform can start to find infringing content. It typically takes a day or two for us to find results, depending on platform and other search parameters.'),
        },
        {
          target: '.tour-all',
          title: renderTitle('Track Searches'),
          content: renderContent('View and manage search progress and filter by the current status of the search. You can find search details including found results as well as pause or resume active searches.'),
        },
      ] : []),
    ...(reviewEnabled
      ? [
        {
          target: '.tour-review',
          title: renderTitle('Review Tool'),
          content: renderContent('Potentially infringing content found through your searches can be found in the Review Tool. \n\nYou can view the content, select a review status, submit your review, and then our system will take the appropriate action based on your selections.'),
        },
      ] : []),
    ...(authorListsEnabled
      ? [
        {
          target: '.tour-dmca-author_allowlist',
          title: renderTitle('DMCA Author Allowlist'),
          content: renderContent('If you have other authorized channels, you can add them to your allowlist here to ensure that no DMCA notices will be sent for authorized content.'),
        },
      ] : []),
    ...(domainListsEnabled
      ? [
        {
          target: '.tour-dmca-denylist',
          title: renderTitle('DMCA Deny and Allow lists'),
          content: renderContent('The DMCA Denylist allows you to add domains that are known to hold infringing content to avoid the need for manual review. \n\nThe DMCA Allowlist is for any authorized domains; no notices will be sent for any domain that matches one you have added to this list'),
        },
      ] : []),
    ...(overviewEnabled
      ? [
        {
          target: '.tour-piracy',
          title: renderTitle('Piracy Overview'),
          content: renderContent('The Piracy Overview page provides a summary of your searches and results. You can filter by date range, search type, and more.'),
        },
      ] : []),
    ...(reportsEnabled
      ? [
        {
          target: '.tour-reports',
          title: renderTitle('Reporting'),
          content: renderContent('Find detailed analytics reports and summaries of searches and results here.'),
        },
      ] : []),
    {
      target: '.tour-app-container',
      hideFooter: true,
      title: renderTitle('Happy Searching!'),
      content: (
        <Box direction="column" gap="small">
          <StyledText color="var(--primary-text)" weight={400} size="1rem" textAlign="start">
            If you have any further questions, please reach out to us at
            {' '}
            <AppAnchor href="mailto:contact@redflagai.co" target="_blank" color="var(--app-highlight)">
              contact@redflagai.co
            </AppAnchor>
          </StyledText>
          <StyledText size="1rem" color="var(--primary-text)" weight={400} textAlign="start">
            You can restart this tour at any time from the menu in the top right corner.
          </StyledText>
          <Box direction="column" gap="1rem" align="center" justify="center" width="100%">
            <AppButton
              overrideHover
              onClick={handleEndTour}
              level="tableControls"
              color="white"
              bgColor="var(--app-highlight)"
              label="End tour"
            />
          </Box>
        </Box>
      ),
      placement: 'center',
    },
  ];

  if (!showTour) return null;

  return (
    <Box>
      <Joyride
        continuous
        showProgress
        disableScrolling
        disableCloseOnEsc
        hideCloseButton
        showSkipButton
        steps={steps}
        callback={(data) => {
          const { status } = data;

          if (status === 'finished' || status === 'skipped') {
            handleEndTour();
          }
        }}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finished',
          next: 'Next',
          nextLabelWithProgress: 'Next ({step} of {steps})',
          open: 'Open the dialog',
          skip: 'Skip tour',
        }}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: 'var(--app-highlight)',
            textColor: 'var(--primary-text)',
            textSize: '1rem',
            textAlign: 'left',
            spotlightPadding: 10,
          },
        }}
      />
    </Box>
  );
};

function mapStateToProps(state) {
  const { companyFeatures } = state.auth;
  const { showTour } = state.notice;

  return {
    showTour,
    companyFeatures,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    endTour: endTourRequest,
  }, dispatch);
}

GuidedTour.propTypes = {
  showTour: PropTypes.bool.isRequired,
  endTour: PropTypes.func.isRequired,
  companyFeatures: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    config: PropTypes.objectOf(PropTypes.any),
  }).isRequired),
};

export default connect(mapStateToProps, mapDispatchToProps)(GuidedTour);
